export const homeObjOne = { //APROPOS
    id:'apropos',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: 'A propos de moi...',
    headLine: `Je m'appelle Sandara,`,
    description: `Je suis à la recherche de ma première expérience professionnelle afin d'acquérir des compétences et des connaissances, avec l'objectif de devenir un développeur senior à terme.`,
    buttonLabel: '',
    imgStart: false,
    img: require('../../image/svg1.svg').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: true,
    displayTriangle: true,
    displayWave: false,
    displayTilt: false,
    displayBar: false,
    displayBar2: false,
    displayBlob: false,
    displayBlob2: true,
    displayBlob3: true,
    displayBlob4: false,
    BarTitle: false,
}


export const homeObjTwo = { //PARCOURS
    id:'parcours',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Mon parcours',
    headLine: 'Reconversion professionnelle',
    description: 
    `Ancien technicien électronique chez Alstom, j'ai effectué 
    ma reconversion professionnelle grâce au Bootcamp LaCapsule. J'ai choisi
    le code car il m'anène constammement à la réflexion puis à de la créativité.`,
    buttonLabel: 'Télécharger mon CV',
    imgStart: true,
    img: require('../../image/svg3.svg').default,
    alt: 'Car',
    dark: false,
    primary: true,
    darkText: false,
    displayTriangle: false,
    displayWave: false,
    displayTilt: false,
    displayBar: false,
    displayBar2: false,
    displayBlob: false,
    displayBlob2: false,
    displayBlob3: false,
    displayBlob4: false,
    barTitle: false,

}

export const homeObjFour = { //COMPETENCES
    id:'competences',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Compétences',
    headLine: 'Je suis un Développeur Junior MERN',
    description: `Au cours de ma formation j'ai appris les stacks suivantes :
    MongoDB - Express - React - Node`,
    buttonLabel: 'MERN',
    imgStart: false,
    img: require('../../image/svg2.svg').default,
    alt: 'Job',
    dark: false,
    primary: true,
    darkText: true,
    displayTriangle: false,
    displayWave: true,
    displayTilt: true,
    displayBar: false,
    displayBar2: false,
    displayBlob: false,
    displayBlob2: false,
    displayBlob3: false,
    displayBlob4: true,
    barTitle: false,
}

export const homeObjThree = { //PROJETS
    id:'projets',
    lightBg: false,
    lightText: true,
    lightTextDesc: false,
    topLine: 'projets',
    headLine: 'Voici plus bas quelques projets',
    description: `Pendant ma formation j'ai pu voir comment implémenter différents élements 
    pour élaborer une webapp complète: front, back, base de données, api, en passant par les outils de collaborations comme git, github, trello puis aussi les outils de design comme Figma, Whimsical pour élaborer des story boards etc`,
    buttonLabel: 'Mon GitHub',
    imgStart: true,
    img: require('../../image/svg4.svg').default,
    alt: 'Job',
    dark: false,
    primary: true,
    darkText: false,
    displayTriangle: false,
    displayWave: false,
    displayTilt: false,
    displayBar: true,
    displayBar2: true,
    displayBlob: false,
    displayBlob2: false,
    displayBlob3: false,
    displayBlob4: false,
    barTitle: true,
}
